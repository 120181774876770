html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  font-family: sans-serif;
}

body {
  margin: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  display: block;
}

audio, canvas, progress, video {
  vertical-align: baseline;
  display: inline-block;
}

audio:not([controls]) {
  height: 0;
  display: none;
}

[hidden], template {
  display: none;
}

a {
  background-color: #0000;
}

a:active, a:hover {
  outline: 0;
}

abbr[title] {
  border-bottom: 1px dotted;
}

b, strong {
  font-weight: bold;
}

dfn {
  font-style: italic;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

mark {
  color: #000;
  background: #ff0;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sup {
  top: -.5em;
}

sub {
  bottom: -.25em;
}

img {
  border: 0;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 1em 40px;
}

hr {
  box-sizing: content-box;
  height: 0;
}

pre {
  overflow: auto;
}

code, kbd, pre, samp {
  font-family: monospace;
  font-size: 1em;
}

button, input, optgroup, select, textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}

button {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, html input[type="button"], input[type="reset"], input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}

button[disabled], html input[disabled] {
  cursor: default;
}

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input {
  line-height: normal;
}

input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box;
}

input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

fieldset {
  border: 1px solid silver;
  margin: 0 2px;
  padding: .35em .625em .75em;
}

legend {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
}

optgroup {
  font-weight: bold;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td, th {
  padding: 0;
}

html {
  color: #222;
  font-size: 1em;
  line-height: 1.4;
}

::selection {
  text-shadow: none;
  background: #cbd2e4;
}

.col-right ::selection {
  color: #3c424f;
  text-shadow: none;
  background: #cbd2e4;
}

hr {
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
  display: block;
}

audio, canvas, iframe, img, svg, video {
  vertical-align: middle;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

.hidden {
  visibility: hidden;
  display: none !important;
}

.visuallyhidden {
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  border: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.visuallyhidden.focusable:active, .visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  width: auto;
  margin: 0;
  position: static;
  overflow: visible;
}

.invisible {
  visibility: hidden;
}

.clearfix:before, .clearfix:after {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}

@font-face {
  font-family: Slate W01 Medium;
  src: url("Slate-W01-Book.2e46243b.eot#iefix");
  src: url("Slate-W01-Book.2e46243b.eot#iefix") format("eot"), url("Slate-W01-Book.d1d86b42.woff2") format("woff2"), url("Slate-W01-Book.62b7be12.woff") format("woff"), url("Slate-W01-Book.0814ab73.ttf") format("truetype"), url("Slate-W01-Book.1df91d9a.svg#Slate-W01-Book") format("svg");
  font-display: swap;
}

@font-face {
  font-family: Slate W01 Bk;
  src: url("Slate-W01-Medium.0b0cf94c.eot#iefix");
  src: url("Slate-W01-Medium.0b0cf94c.eot#iefix") format("eot"), url("Slate-W01-Medium.ce94c56f.woff2") format("woff2"), url("Slate-W01-Medium.5f5cbd87.woff") format("woff"), url("Slate-W01-Medium.0b6a5063.ttf") format("truetype"), url("Slate-W01-Medium.5d3e4a71.svg#Slate-W01-Medium") format("svg");
  font-display: swap;
}

html {
  -webkit-text-size-adjust: 100%;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizelegibility;
  height: auto;
  min-height: 100%;
  font-family: Slate W01 Book, Helvetica, Arial, sans-serif;
  font-size: 18px;
}

body {
  color: #3c424f;
  background: url("Madrid.673c184c.jpg") center / cover no-repeat;
  margin: 0;
  padding: 0;
  line-height: 1.7em;
}

h1, h2 {
  color: #3c424f;
  margin: 0 0 1em;
  font-family: Slate W01 Medium;
  font-size: 2.2em;
  line-height: 1;
}

h1 {
  color: #cbd2e4;
  margin-bottom: 1em 0 0;
  font-size: 1.3em;
}

a {
  color: #63c9fb;
  text-decoration: underline;
  transition: all .25s ease-in-out;
}

a:hover {
  text-decoration: none;
}

.link-animation {
  color: #3c424f;
  padding: 0 2px;
  text-decoration: none;
  display: inline-block;
  position: relative;
}

.link-animation span {
  z-index: 2;
  display: block;
  position: relative;
}

.link-animation:hover {
  color: #33a4ec;
}

.link-animation:after {
  content: "";
  height: 4px;
  width: 100%;
  background-color: #63c9fb;
  border-radius: 2px;
  transition: all .4s cubic-bezier(.7, 0, .3, 1);
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translate3d(0, 0, 0);
}

.link-animation:hover:after {
  height: 100%;
  background-color: #e9f7ff;
  border-radius: 4px;
}

.video-container {
  display: none;
}

.card {
  width: 94%;
  flex-direction: column-reverse;
  margin: 2em auto;
}

.col-left {
  background-color: #fffffffa;
  flex-basis: 67%;
}

.col-right {
  text-align: center;
  color: #cbd2e4;
  background-color: #3c424ffa;
  flex-basis: 33%;
}

.roundImg {
  border-radius: 100%;
}

.col-right a.link-animation {
  color: #cbd2e4;
}

.col-right a.link-animation:hover {
  color: #33a4ec;
}

.intro, .info {
  padding: 2em 3em;
}

.emailme {
  margin-bottom: 0;
}

.pic {
  margin-top: 0;
}

.social {
  margin-top: 3em;
  margin-bottom: 0;
}

a.icon {
  margin: .15em;
  text-decoration: none;
}

a.icon svg {
  fill: #cbd2e4;
  transition: all .2s;
}

a.icon:hover svg {
  fill: #33a4ec;
}

a.normal-link {
  color: #cbd2e4;
  text-decoration: none;
}

@media (min-width: 992px) {
  html, body {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  body {
    background: none;
  }

  .card {
    width: 940px;
    flex-direction: row;
    margin: 0;
    display: flex;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .video-container {
    height: 100vh;
    justify-content: center;
    align-items: center;
    display: flex;
    position: relative;
    overflow: hidden;
  }

  #vidBG {
    vertical-align: baseline;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -100;
    background: url("Madrid.673c184c.jpg") center / cover no-repeat;
    margin: auto;
    display: inline-block;
    position: absolute;
    inset: -100%;
  }
}

/*# sourceMappingURL=index.b6b2b5fd.css.map */
