

@font-face{
  font-family: "Slate W01 Medium";
  src:url("../assets/fonts/Slate-W01-Book.eot?#iefix");
  src:url("../assets/fonts/Slate-W01-Book.eot?#iefix") format("eot"),url("../assets/fonts/Slate-W01-Book.woff2") format("woff2"),url("../assets/fonts/Slate-W01-Book.woff") format("woff"),url("../assets/fonts/Slate-W01-Book.ttf") format("truetype"),url("../assets/fonts/Slate-W01-Book.svg#Slate-W01-Book") format("svg");
  font-display: swap;
}
@font-face{
  font-family: "Slate W01 Bk";
  src:url("../assets/fonts/Slate-W01-Medium.eot?#iefix");
  src:url("../assets/fonts/Slate-W01-Medium.eot?#iefix") format("eot"),url("../assets/fonts/Slate-W01-Medium.woff2") format("woff2"),url("../assets/fonts/Slate-W01-Medium.woff") format("woff"),url("../assets/fonts/Slate-W01-Medium.ttf") format("truetype"),url("../assets/fonts/Slate-W01-Medium.svg#Slate-W01-Medium") format("svg");
  font-display: swap;
}


html {
  font-family: 'Slate W01 Book', Helvetica, Arial, sans-serif;
  font-size: 18px;
  -webkit-text-size-adjust: 100%;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  height: auto;
  min-height: 100%;
}


body {
  margin: 0;
  padding: 0;
  line-height: 1.7em;
  color: #3C424F;
  background: url('../assets/vids/Madrid.jpg') no-repeat center center;
  background-size: cover;
}

h1,
h2 {
  font-family: 'Slate W01 Medium';
  font-size: 2.2em;
  color: #3c424f;
  line-height: 1;
  margin: 0 0 1em;
}

h1 {
  color: #cbd2e4;
  font-size: 1.3em;
  margin-bottom: 1em 0 0;
}

a {
  color: #63c9fb;
  text-decoration: underline;
  transition: all .25s ease-in-out;
}
  a:hover {
    text-decoration: none;
  }

.link-animation {
  position: relative;
  color: #3c424f;
  display: inline-block;
  padding: 0 2px;
  text-decoration: none;
}
  .link-animation span {
    display: block;
    position: relative;
    z-index: 2;
  }
  .link-animation:hover {
    color: #33a4ec;
  }
  .link-animation:after {
    content: '';
    position: absolute;
    height: 4px;
    width: 100%;
    bottom: 0;
    right: 0;
    transform: translate3d(0,0,0);
    transition: all .4s;
    transition-timing-function: cubic-bezier(.7,0,.3,1);
    border-radius: 2px;
    background-color: #63c9fb;
  }
    .link-animation:hover:after {
      height: 100%;
      background-color: #e9f7ff;
      border-radius: 4px;
    }
.video-container {
  display: none;
}

.card {
  width: 94%;
  margin: 2em auto;
  flex-direction: column-reverse;
}

.col-left {
  background-color: rgba(255, 255, 255, 0.98);
  flex-basis: 67%;
}
.col-right {
  text-align: center;
  color: #CBD2E4;
  background-color: rgba(60, 66, 79, 0.98);
  flex-basis: 33%;
}
  .roundImg {
    border-radius: 100%;
  }
  .col-right a.link-animation {
    color: #CBD2E4;
  }
    .col-right a.link-animation:hover {
      color: #33a4ec;
    }

.intro,
.info {
  padding: 2em 3em;
}
.emailme {
  margin-bottom: 0;
}
.pic {
  margin-top: 0;
}
.social {
  margin-top: 3em;
  margin-bottom: 0;
}
a.icon {
  text-decoration: none;
  margin: 0.15em;
}
a.icon svg {
  transition: all .2s;
  fill: #CBD2E4;
}
  a.icon:hover svg {
    fill: #33a4ec;
  }
a.normal-link {
  text-decoration: none;
  color: #CBD2E4;
}

@media (min-width: 992px) {
  html, body {
    width: 100%;
    height:100%;
    overflow:hidden;
  }
  body {
    background: none;
  }
  .card {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 940px;
    margin: 0;

    display: flex;
    flex-direction: row;
  }
  .video-container {
    display: flex;

    position: relative;
    overflow: hidden;
    height: 100vh;
    justify-content: center;
    align-items: center;
  }
  #vidBG {
    position: absolute;
    display: inline-block;
    vertical-align: baseline;

    background: url('../assets/vids/Madrid.jpg') no-repeat center center;
    background-size: cover;
    margin: auto;
    width: 100%;
    height: 100%;
    right: -100%;
    bottom: -100%;
    top: -100%;
    left: -100%;
    object-fit: cover;
    z-index: -100;
  }
}
